
// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules"; // Import Autoplay module
// import "swiper/css"; // Swiper styles
// import BgImage from "../../assets/bg.png";
// import { motion } from "framer-motion";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
// import { Link } from "react-router-dom";

// // Background style
// const bgStyle = {
//   backgroundImage: `url(${BgImage})`,
//   backgroundRepeat: "no-repeat",
//   backgroundSize: "cover",
//   backgroundPosition: "center",
// };

// const reviews = [
//   {
//     id: 1,
//     text: "Sankhyana Consultancy has transformed my career. The courses are top-notch!",
//     author: "Eric Kutto",
//     designation: "Full Stack Development",
//   },
//   {
//     id: 2,
//     text: "The learning experience was excellent! Highly recommend their services.",
//     author: "Ayushi Chouhan",
//     designation: "Full Stack Development",
//   },
//   {
//     id: 3,
//     text: "I gained valuable skills that helped me land my dream job. Thank you, Sankhyana!",
//     author: "Edwin Caxton",
//     designation: "Full Stack Development",
//   },
//   {
//     id: 4,
//     text: "Amazing consultancy! The support from the mentors is incredible.",
//     author: "Erick Bundi",
//     designation: "Data Science",
//   },
//   {
//     id: 5,
//     text: "Sankhyana Consultancy has transformed my career. The courses are top-notch!",
//     author: "Jeremiah",
//     designation: "Data Science",
//   },
//   {
//     id: 6,
//     text: "The learning experience was excellent! Highly recommend their services.",
//     author: "Odhiambo Phillip Santei",
//     designation: "Data Science",
//   },
// ]






// const Subscribe = () => {
//   return (
//     <section className="relative w-full h-screen overflow-hidden">
//       <motion.div
//         initial={{ opacity: 0 }}
//         whileInView={{ opacity: 1 }}
//         style={bgStyle}
//         className="absolute inset-0 w-full h-full"
//       >
//         <div className="absolute inset-0 bg-black opacity-30 backdrop-blur-sm"></div>

//         <motion.div
//           initial={{ opacity: 0, scale: 0.5 }}
//           whileInView={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.6, ease: "easeInOut" }}
//           className="relative z-10 flex flex-col justify-center items-center h-full text-center"
//         >
//           <h1 className="text-4xl font-bold text-white mb-8">What Our Students Say</h1>

//           <div className="w-full max-w-6xl mx-auto"> {/* Constrain the width of the Swiper */}
//             <Swiper
//               slidesPerView={3} // Show three slides at a time
//               spaceBetween={30}
//               loop={true}
//               autoplay={{
//                 delay: 3000,
//                 disableOnInteraction: false,
//               }}
//               modules={[Autoplay]} // Include only Autoplay module
//               className="mySwiper"
//             >
//               {reviews.map((review) => (
//                 <SwiperSlide key={review.id}>
//                   <div className="mx-0 my-5">
                 
//                     <Card
//                       className="hover:scale-105 transition-transform duration-300 border-gray-300 shadow-md"
//                       variant="outlined"
//                       style={{ backgroundColor: "#ffffff", height: "150px", width: "300px" }} // Set a fixed height for uniformity
//                     >
//                       <CardContent className="flex flex-col justify-center">
//                         <Typography variant="body1" color="textPrimary" align="center" className="text-gray-700">
//                           "{review.text}"
//                         </Typography>
//                         <Typography variant="h6" component="h2" align="center" className="mt-4 text-gray-800">
//                           {review.author}
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary" align="center" className="text-gray-500">
//                           {review.designation}
//                         </Typography>
//                       </CardContent>
//                     </Card>

                  
//                   </div>
//                 </SwiperSlide>
//               ))}
//             </Swiper>
//           </div>
//         </motion.div>
//       </motion.div>
//     </section>
//   );
// };

// export default Subscribe;














import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules"; // Import Autoplay module
import "swiper/css"; // Swiper styles
import BgImage from "../../assets/bg.png";
import { motion } from "framer-motion";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

// Background style
const bgStyle = {
  backgroundImage: `url(${BgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

// const reviews = [
//   {
//     id: 1,
//     text: "Sankhyana Consultancy has transformed my career. The courses are top-notch!",
//     author: "Eric Kutto",
//     designation: "Full Stack Development",
//   },
//   {
//     id: 2,
//     text: "The learning experience was excellent! Highly recommend their services.",
//     author: "Ayushi Chouhan",
//     designation: "Full Stack Development",
//   },
//   {
//     id: 3,
//     text: "I gained valuable skills that helped me land my dream job. Thank you, Sankhyana!",
//     author: "Edwin Caxton",
//     designation: "Full Stack Development",
//   },
//   {
//     id: 4,
//     text: "Amazing consultancy! The support from the mentors is incredible.",
//     author: "Erick Bundi",
//     designation: "Data Science",
//   },
//   {
//     id: 5,
//     text: "Sankhyana Consultancy has transformed my career. The courses are top-notch!",
//     author: "Jeremiah",
//     designation: "Data Science",
//   },
//   {
//     id: 6,
//     text: "The learning experience was excellent! Highly recommend their services.",
//     author: "Odhiambo Phillip Santei",
//     designation: "Data Science",
//   },
// ]

const reviews = [
  {
    id: 1,
    text: "The expert-led sessions and hands-on projects at Sankhyana gave me the skills and confidence to excel in data engineering. The interview preparation was top-notch, helping me land my dream job!",
    author: "Ananya",
    designation: "Data Engineering Course",
  },
  {
    id: 2,
    text: "Sankhyana’s Full Stack course gave me a deep understanding of the entire software development process, improving my problem-solving and adaptability in various projects.",
    author: "Aravind",
    designation: "Full Stack Course",
  },
  {
    id: 3,
    text: "The Data Engineering course offered practical learning with real-world projects and advanced tools like Hadoop and Spark, helping me stay competitive in the field.",
    author: "Arjun",
    designation: "Data Engineering Course",
  },
  {
    id: 4,
    text: "Sankhyana’s Data Science course equipped me with both fundamental and advanced skills, empowering me to tackle real data challenges with confidence.",
    author: "Swetha",
    designation: "Data Science Course",
  },
  {
    id: 5,
    text: "The comprehensive curriculum and hands-on projects significantly boosted my confidence in applying full-stack development skills in real-world scenarios.",
    author: "Sam Visual",
    designation: "Full Stack Course",
  },
  {
    id: 6,
    text: "The Full Stack course exceeded my expectations, taking me from basic coding skills to confidently building and deploying full-scale applications.",
    author: "Abrahim",
    designation: "Full Stack Course",
  },
  {
    id: 7,
    text: "Sankhyana’s Data Science course was well-structured with hands-on projects, providing a great balance between theory and practical application.",
    author: "Arun Kumar",
    designation: "Data Science Course",
  },
  {
    id: 8,
    text: "The hands-on experience and real-world projects at Sankhyana gave me a deep understanding of full-stack technologies and prepared me for real-world challenges.",
    author: "Austin Kuria Maina",
    designation: "Full Stack Course",
  },
  {
    id: 9,
    text: "Sankhyana’s Data Science course provided a rich learning experience with real-world scenarios, hands-on projects, and excellent mentorship throughout.",
    author: "Morgan Mutuku",
    designation: "Data Science",
  },
  {
    id: 10,
    text: "The Data Engineering course was comprehensive and hands-on, covering essential tools and techniques that have fully prepared me for a successful career in the field.",
    author: "Abiud Mwendwa",
    designation: "Data Engineering Batch",
  },
  {
    id: 11,
    text: "The personalized guidance and hands-on projects helped me master complex topics and prepared me to succeed as a Data Scientist.",
    author: "Nkopane Lethola",
    designation: "Data Science Batch",
  },
];



const Subscribe = () => {
  return (
    <section className="relative w-full h-96 md:h-screen overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        style={bgStyle}
        className="absolute inset-0 w-full h-80 md:h-full"
      >
        <div className="absolute inset-0 bg-black opacity-30 backdrop-blur-sm"></div>

        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          className="relative z-10 flex flex-col justify-center items-center h-full text-center"
        >
          <h1 className="text-4xl font-bold text-white mb-8">What Our Students Say</h1>

          <div className="w-full max-w-6xl mx-auto">
            <Swiper
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]} // Include only Autoplay module
              breakpoints={{
                320: {
                  slidesPerView: 1, // 1 slide on mobile
                },
                768: {
                  slidesPerView: 2, // 2 slides on tablets
                },
                1024: {
                  slidesPerView: 3, // 3 slides on laptops and larger screens
                },
              }}
              className="mySwiper"
            >
              {reviews.map((review) => (
                <SwiperSlide key={review.id}>
                  <div className="flex justify-center"> {/* Center slide content */} 
                    <Card
                      className="hover:scale-105 transition-transform duration-300 border-gray-300 shadow-md mx-auto" // Centering the card
                      variant="outlined"
                      style={{ backgroundColor: "#ffffff", height: "240px", width: "300px" }} // Set a fixed height for uniformity
                    >
                      <CardContent className="flex flex-col justify-center text-center"> {/* Centered text */} 
                        <Typography variant="body1" color="textPrimary" className="text-gray-700">
                          "{review.text}"
                        </Typography>
                        <Typography variant="h6" component="h2" className="mt-4 text-gray-800">
                          {review.author}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className="text-gray-500">
                          {review.designation}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </motion.div>
      </motion.div>

      {/* Responsive styles for mobile screens */}
      <style jsx>{`
        @media (max-width: 640px) {
          .swiper {
            padding: 0 20px; // Add horizontal padding for mobile
          }
          .swiper-slide {
            display: flex; // Center content in the slide
            justify-content: center; // Center horizontally
            align-items: center; // Center vertically
          }
          .MuiCard-root {
            width: 90%; // Use a smaller width for cards on mobile
            height: auto; // Allow height to adjust based on content
          }
          h1 {
            font-size: 2rem; // Smaller heading on mobile
          }
          .swiper-slide {
            min-width: 250px; // Ensure a minimum width for smaller screens
          }
        }
      `}</style>
    </section>
  );
};

export default Subscribe;