import React from 'react';
import './Lms.css';
import LazyLoad from 'react-lazy-load';
import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";
import { Ri24HoursFill } from "react-icons/ri";
import { BsPersonLinesFill } from "react-icons/bs";

import lms_back from './Image/lms_back.png';
import Data_science from './Image/Data Science.webp'
import Data_engineering from './Image/Data Engineering.webp'
import Devops from './Image/DevOPs.webp'
import Full_stack from './Image/Full Stack.webp'
import generative from './Image/Generative AI.webp'
import social_media from './Image/Social Media Marketing.webp'
import llm from './Image/LLM.webp'



function Lms() {
  // Array to store card data with icon, color, and background color
  const cardData = [
    { 
      title: 'Expert Instructor', 
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.', 
      icon: <FaChalkboardTeacher />,
      color: 'text-purple-700',
      bgColor: 'bg-purple-50' 
    },
    { 
      title: '24/7 Support Available', 
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.', 
      icon: <Ri24HoursFill />, 
      color: 'text-yellow-700',
      bgColor: 'bg-yellow-50' 
    },
    { 
      title: 'Become An Instructor', 
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.', 
      icon: <FaUserGraduate />, 
      color: 'text-green-500',
      bgColor: 'bg-green-50' 
    },
    { 
      title: 'Learn Anywhere', 
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.', 
      icon: <BsPersonLinesFill />, 
      color: 'text-red-500',
      bgColor: 'bg-red-50' 
    },
  ];

  // Array to store course exploration data
  const courseData = [
    { title: 'Data Science', description: 'Course description goes here.', image: Data_science },
    { title: 'Data Engineering', description: 'Course description goes here.', image: Data_engineering },
    { title: 'DevOps', description: 'Course description goes here.', image: Devops },
    { title: 'Full Stack', description: 'Course description goes here.', image: Full_stack },
    { title: 'Generative AI', description: 'Course description goes here.', image: generative },
    { title: 'Social Media Marketing', description: 'Course description goes here.', image: social_media },
    { title: 'LLM', description: 'Course description goes here.', image: llm },
  ];
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <LazyLoad offsetVertical={300} debounce={false} once>
              <img 
                src={lms_back} 
                alt="best software training institute in Bangalore" 
                loading="lazy" 
                className="img-fluid"
                style={{ height: "500px", width: "100%" }}
              />
            </LazyLoad>
          </div>
        </div>

        <div className="row d-flex justify-content-center pt-4">
          {/* Render cards */}
          {cardData.map((card, index) => (
            <div key={index} className="col-md-3 mb-3">
              <div className={`card ${card.bgColor}`} style={{ border: 'none' }}>
                <div className="card-body">
                  <h5 className={`card-title d-flex align-items-center fw-bold fs-5 ${card.color}`}>
                    <span className="me-2">{card.icon}</span>
                    {card.title}
                  </h5>
                  <p className="card-text text-justify pt-2">{card.text}</p>
                </div>
                <span className="text-decoration-underline d-flex cursor-pointer pb-3 pl-3 fw-bold">
                  Read More
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className='row d-flex justify-content-center pt-3'>
          <div className='col-md-6'>
            <h1 className='h1 text-center'>Explore Courses </h1>
          </div>
        </div>

        {/* Render course exploration sections */}
        <div className='row d-flex justify-content-center'>
  {courseData.map((course, index) => (
    <div key={index} className="col-md-3 my-2">
      <div className="w-100 h-100 shadow-lg rounded bg-light">
        <LazyLoad offsetVertical={500} debounce={false} once>
          <img src={course.image} alt={course.title} className="img-fluid rounded-top" />
        </LazyLoad>
        <div className="p-3">
          <h2 className="h4 text-blue-900 text-center">{course.title}</h2>
          <p className="text-center">{course.description}</p>
        </div>
      </div>
    </div>
  ))}
</div>

<div className='row pt-5'>
    <div className='col-md-5 bg-danger'>ghf</div>
    <div className='col-md-7 bg-primary'>fgd</div>

</div>

      </div>
    </>
  );
}

export default Lms;