import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Enroll, enrollnow } from '../../utils/constant';
import { Circles } from 'react-loader-spinner';

function EnrollNow() {
  const navigate = useNavigate();
  const { batchname } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialFormData = {
    batchname: decodeURIComponent(batchname) || '',
    name: '',
    email: '',
    mobileNo: '',
    city: '',
  };

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobileNo: '',
    city: '',
    serverError: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
      serverError: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = validateForm();
    if (formValid) {
      setIsLoading(true);
      try {
        const storeDataResponse = await fetch(enrollnow, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (storeDataResponse.ok) {
          const sendEmailResponse = await fetch(Enroll, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...formData,
              message: 'Thank you for enrolling!',
            }),
          });

          if (sendEmailResponse.ok) {
            navigate('/Thankyou');
          } else {
            console.error('Failed to send email');
          }
        } else {
          const errorData = await storeDataResponse.json();
          setErrors((prevErrors) => ({
            ...prevErrors,
            serverError: errorData.message || 'Failed to store data',
          }));
        }
      } catch (error) {
        console.error('Error:', error);
        setErrors((prevErrors) => ({
          ...prevErrors,
          serverError: 'An unexpected error occurred',
        }));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateForm = () => {
    const { name, email, mobileNo, city } = formData;
    const newErrors = {};

    if (!name.trim()) newErrors.name = 'Please enter student name';
    if (!email.trim()) newErrors.email = 'Please enter email';
    if (!mobileNo.trim()) newErrors.mobileNo = 'Please enter mobile number';
    if (!city.trim()) newErrors.city = 'Please enter city';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (batchname) {
      const decodedBatchname = decodeURIComponent(batchname);
      setFormData((prevData) => ({
        ...prevData,
        batchname: decodedBatchname,
      }));
    }
  }, [batchname]);

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              {`${decodeURIComponent(batchname)}`}
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              {/* {errors.serverError && (
                <p className="text-red-500 text-sm">{errors.serverError}</p>
              )} */}
              <div>
                <label htmlFor="batchname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Batch Name
                </label>
                <input
                  type="text"
                  name="batchname"
                  id="batchname"
                  value={formData.batchname}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={decodeURIComponent(batchname)}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Student Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.name ? 'border-red-500' : ''}`}
                  placeholder="Student Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'border-red-500' : ''}`}
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>
              <div>
                <label htmlFor="mobileNo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="mobileNo"
                  id="mobileNo"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.mobileNo ? 'border-red-500' : ''}`}
                  placeholder="Mobile Number"
                  value={formData.mobileNo}
                  onChange={handleChange}
                  required
                />
                {errors.mobileNo && <p className="text-red-500 text-xs mt-1">{errors.mobileNo}</p>}
              </div>
              <div>
                <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.city ? 'border-red-500' : ''}`}
                  placeholder="City"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
                {errors.city && <p className="text-red-500 text-xs mt-1">{errors.city}</p>}
              </div>
              {errors.serverError && (
                <p className="text-red-500 text-sm">{errors.serverError}</p>
              )}
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {isLoading ? <Circles color="#00BFFF" height={24} width={24} /> : 'Enroll Now'}
              </button>
              
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EnrollNow;




