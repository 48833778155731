// import React from "react";
// import { AiOutlineDatabase, AiOutlineRobot, AiOutlineBulb } from "react-icons/ai";
// import { FiBarChart2, FiServer } from "react-icons/fi";
// import { FaAws, FaJava } from "react-icons/fa";
// import { SiApacheairflow, SiApachespark, SiShell } from "react-icons/si";
// import { motion } from "framer-motion";
// import { FaTerminal } from "react-icons/fa";

// const ServicesData = [
//   {
//     id: 1,
//     title: "Data Mining with Python",
//     link: "#",
//     icon: <AiOutlineDatabase />,
//     delay: 0.2,
//   },
//   {
//     id: 2,
//     title: "Statistics with Python",
//     link: "#",
//     icon: <FiBarChart2 />,
//     delay: 0.3,
//   },
//   {
//     id: 3,
//     title: "Machine Learning with Python",
//     link: "#",
//     icon: <AiOutlineRobot />,
//     delay: 0.4,
//   },
//   {
//     id: 4,
//     title: "Artificial Intelligence with Python",
//     link: "#",
//     icon: <AiOutlineBulb />,
//     delay: 0.5,
//   },
// ];

// const ServicesData2 = [
//   {
//     id: 1,
//     title: "Apache Spark",
//     link: "#",
//     icon: <SiApachespark />,
//     delay: 0.2,
//   },
//   {
//     id: 2,
//     title: "Shell Scripting",
//     link: "#",
//     icon: <FaTerminal />,
//     delay: 0.3,
//   },
//   {
//     id: 3,
//     title: "Database Management System",
//     link: "#",
//     icon: <AiOutlineDatabase />,
//     delay: 0.4,
//   },
//   {
//     id: 4,
//     title: "Python Programming for Data Analytics",
//     link: "#",
//     icon: <AiOutlineBulb />,
//     delay: 0.5,
//   },
//   {
//     id: 5,
//     title: "Java for Data Engineering",
//     link: "#",
//     icon: <FaJava />,
//     delay: 0.6,
//   },
//   {
//     id: 6,
//     title: "Cloud Computing – AWS",
//     link: "#",
//     icon: <FaAws />,
//     delay: 0.7,
//   },
//   {
//     id: 7,
//     title: "Data Pipeline using Airflow",
//     link: "#",
//     icon: <SiApacheairflow />,
//     delay: 0.8,
//   },
//   {
//     id: 8,
//     title: "Big Data and Hadoop",
//     link: "#",
//     icon: <AiOutlineDatabase />,
//     delay: 0.9,
//   },
// ];

// const ServicesData3 = [
//   {
//     id: 1,
//     title: "Front-end Development",
//     link: "#",
//     icon: <AiOutlineDatabase />,
//     delay: 0.2,
//   },
//   {
//     id: 2,
//     title: "Back-end Development",
//     link: "#",
//     icon: <FiServer />,
//     delay: 0.3,
//   },
//   {
//     id: 3,
//     title: "Databases",
//     link: "#",
//     icon: <AiOutlineRobot />,
//     delay: 0.4,
//   },
// ];

// const SlideLeft = (delay) => {
//   return {
//     initial: { opacity: 0, x: 50 },
//     whileInView: {
//       opacity: 1,
//       x: 0,
//       transition: {
//         duration: 0.5,
//         delay: delay,
//         ease: "easeInOut",
//       },
//     },
//     viewport: { once: true },
//   };
// };

// const FadeUpText = (delay) => {
//   return {
//     initial: { opacity: 0, y: 30 },
//     whileInView: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.5,
//         delay: delay,
//         ease: "easeInOut",
//       },
//     },
//     viewport: { once: true },
//   };
// };

// const Services = () => {
//   return (
//     <section className="bg-white py-16">
//       <div className="container mx-auto px-6">
//         {/* Section Header with Text Animations */}
//         <motion.h1
//           variants={FadeUpText(0.1)}
//           initial="initial"
//           whileInView="whileInView"
//           className="text-4xl font-bold text-left pb-10"
//         >
//           Services We Provide
//         </motion.h1>
//         <motion.h2
//           variants={FadeUpText(0.2)}
//           initial="initial"
//           whileInView="whileInView"
//           className="text-2xl font-bold text-left pb-6"
//         >
//           Become a{" "}
//           <span className="text-blue-900 text-3xl">Data Scientist</span>,{" "}
//           <span className="text-blue-900 text-3xl">Data Engineer</span>, or{" "}
//           <span className="text-blue-900 text-3xl">Full Stack Developer</span>{" "}
//           in 4 to 6 Months.
//         </motion.h2>

//         {/* Data Science Modules */}
//         <motion.h3
//           variants={FadeUpText(0.3)}
//           initial="initial"
//           whileInView="whileInView"
//           className="text-2xl text-left pb-4"
//         >
//           Data Science Modules:
//         </motion.h3>
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-12">
//           {ServicesData.map((service) => (
//             <motion.div
//               key={service.id}
//               variants={SlideLeft(service.delay)}
//               initial="initial"
//               whileInView="whileInView"
//               className="bg-gray-100 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center p-6 hover:scale-105 transition-transform duration-300"
//             >
//               <div className="text-4xl mb-4 text-primary">{service.icon}</div>
//               <h1 className="text-lg font-semibold text-center">
//                 {service.title}
//               </h1>
//             </motion.div>
//           ))}
//         </div>

//         <hr className="border-t-2 border-gray-300 my-10" />

//         {/* Data Engineering Modules */}
//         <motion.h3
//           variants={FadeUpText(0.4)}
//           initial="initial"
//           whileInView="whileInView"
//           className="text-2xl text-left pb-4"
//         >
//           Data Engineering Modules:
//         </motion.h3>
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-12">
//           {ServicesData2.map((service) => (
//             <motion.div
//               key={service.id}
//               variants={SlideLeft(service.delay)}
//               initial="initial"
//               whileInView="whileInView"
//               className="bg-gray-100 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center p-6 hover:scale-105 transition-transform duration-300"
//             >
//               <div className="text-4xl mb-4 text-primary">{service.icon}</div>
//               <h1 className="text-lg font-semibold text-center">
//                 {service.title}
//               </h1>
//             </motion.div>
//           ))}
//         </div>

//         <hr className="border-t-2 border-gray-300 my-10" />

//         {/* Full Stack Modules */}
//         <motion.h3
//           variants={FadeUpText(0.5)}
//           initial="initial"
//           whileInView="whileInView"
//           className="text-2xl text-left pb-4"
//         >
//           Full Stack Modules:
//         </motion.h3>
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
//           {ServicesData3.map((service) => (
//             <motion.div
//               key={service.id}
//               variants={SlideLeft(service.delay)}
//               initial="initial"
//               whileInView="whileInView"
//               className="bg-gray-100 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center p-6 hover:scale-105 transition-transform duration-300"
//             >
//               <div className="text-4xl mb-4 text-primary">{service.icon}</div>
//               <h1 className="text-lg font-semibold text-center">
//                 {service.title}
//               </h1>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Services;















import React from "react";
import { AiOutlineDatabase, AiOutlineRobot, AiOutlineBulb } from "react-icons/ai";
import { FiBarChart2, FiServer } from "react-icons/fi";
import { FaAws, FaJava } from "react-icons/fa";
import { SiApacheairflow, SiApachespark, SiShell } from "react-icons/si";
import { motion } from "framer-motion";
import { FaTerminal } from "react-icons/fa";

const ServicesData = [
  {
    id: 1,
    title: "Data Mining with Python",
    link: "#",
    icon: <AiOutlineDatabase />,
    delay: 0.2,
  },
  {
    id: 2,
    title: "Statistics with Python",
    link: "#",
    icon: <FiBarChart2 />,
    delay: 0.3,
  },
  {
    id: 3,
    title: "Machine Learning with Python",
    link: "#",
    icon: <AiOutlineRobot />,
    delay: 0.4,
  },
  {
    id: 4,
    title: "Artificial Intelligence with Python",
    link: "#",
    icon: <AiOutlineBulb />,
    delay: 0.5,
  },
];

const ServicesData2 = [
  {
    id: 1,
    title: "Apache Spark",
    link: "#",
    icon: <SiApachespark />,
    delay: 0.2,
  },
  {
    id: 2,
    title: "Shell Scripting",
    link: "#",
    icon: <FaTerminal />,
    delay: 0.3,
  },
  {
    id: 3,
    title: "Database Management System",
    link: "#",
    icon: <AiOutlineDatabase />,
    delay: 0.4,
  },
  {
    id: 4,
    title: "Python Programming for Data Analytics",
    link: "#",
    icon: <AiOutlineBulb />,
    delay: 0.5,
  },
  {
    id: 5,
    title: "Java for Data Engineering",
    link: "#",
    icon: <FaJava />,
    delay: 0.6,
  },
  {
    id: 6,
    title: "Cloud Computing – AWS",
    link: "#",
    icon: <FaAws />,
    delay: 0.7,
  },
  {
    id: 7,
    title: "Data Pipeline using Airflow",
    link: "#",
    icon: <SiApacheairflow />,
    delay: 0.8,
  },
  {
    id: 8,
    title: "Big Data and Hadoop",
    link: "#",
    icon: <AiOutlineDatabase />,
    delay: 0.9,
  },
];

const ServicesData3 = [
  {
    id: 1,
    title: "Front-end Development",
    link: "#",
    icon: <AiOutlineDatabase />,
    delay: 0.2,
  },
  {
    id: 2,
    title: "Back-end Development",
    link: "#",
    icon: <FiServer />,
    delay: 0.3,
  },
  {
    id: 3,
    title: "Databases",
    link: "#",
    icon: <AiOutlineRobot />,
    delay: 0.4,
  },
];

const SlideLeft = (delay) => {
  return {
    initial: { opacity: 0, x: 50 },
    whileInView: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: delay,
        ease: "easeInOut",
      },
    },
    viewport: { once: true },
  };
};

const FadeUpText = (delay) => {
  return {
    initial: { opacity: 0, y: 30 },
    whileInView: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: delay,
        ease: "easeInOut",
      },
    },
    viewport: { once: true },
  };
};

const Services = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-6">
        {/* Section Header with Text Animations */}
        <motion.h1
          variants={FadeUpText(0.1)}
          initial="initial"
          whileInView="whileInView"
          className="text-xl sm:text-2xl pb-3 lg:text-4xl font-bold text-left lg:pb-10"
        >
          Services We Provide
        </motion.h1>
        <motion.h2
          variants={FadeUpText(0.2)}
          initial="initial"
          whileInView="whileInView"
          className="text-xs sm:text-sm lg:text-2xl font-bold text-left lg:pb-6"
        >
          Become a{" "}
          <span className="text-lg sm:text-xl text-blue-900 lg:text-3xl ">Data Scientist</span>,{" "}
          <span className="text-lg sm:text-xl text-blue-900 lg:text-3xl ">Data Engineer</span>, or{" "}
          <span className="text-lg sm:text-xl text-blue-900 lg:text-3xl ">Full Stack Developer</span>{" "}
          in 4 to 6 Months.
        </motion.h2>

        {/* Data Science Modules */}
        <motion.h3
          variants={FadeUpText(0.3)}
          initial="initial"
          whileInView="whileInView"
          className="text-lg sm:text-xl lg:text-2xl text-left pb-4 font-bold"
        >
          Data Science Modules:
        </motion.h3>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-12">
          {ServicesData.map((service) => (
            <motion.div
              key={service.id}
              variants={SlideLeft(service.delay)}
              initial="initial"
              whileInView="whileInView"
              className="bg-gray-100 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center p-6 hover:scale-105 transition-transform duration-300"
            >
              <div className="text-3xl sm:text-4xl mb-4 text-primary">{service.icon}</div>
              <h1 className="text-sm sm:text-lg font-semibold text-center">
                {service.title}
              </h1>
            </motion.div>
          ))}
        </div>

        <hr className="border-t-2 border-gray-300 my-10" />

        {/* Data Engineering Modules */}
        <motion.h3
          variants={FadeUpText(0.4)}
          initial="initial"
          whileInView="whileInView"
          className="text-lg sm:text-xl lg:text-2xl text-left pb-4 font-bold"
        >
          Data Engineering Modules:
        </motion.h3>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-12">
          {ServicesData2.map((service) => (
            <motion.div
              key={service.id}
              variants={SlideLeft(service.delay)}
              initial="initial"
              whileInView="whileInView"
              className="bg-gray-100 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center p-6 hover:scale-105 transition-transform duration-300"
            >
              <div className="text-3xl sm:text-4xl mb-4 text-primary">{service.icon}</div>
              <h1 className="text-sm sm:text-lg font-semibold text-center">
                {service.title}
              </h1>
            </motion.div>
          ))}
        </div>

        <hr className="border-t-2 border-gray-300 my-10" />

        {/* Full Stack Modules */}
        <motion.h3
          variants={FadeUpText(0.5)}
          initial="initial"
          whileInView="whileInView"
          className="text-lg sm:text-xl lg:text-2xl text-left pb-4 font-bold"
        >
          Full Stack Modules:
        </motion.h3>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {ServicesData3.map((service) => (
            <motion.div
              key={service.id}
              variants={SlideLeft(service.delay)}
              initial="initial"
              whileInView="whileInView"
              className="bg-gray-100 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center p-6 hover:scale-105 transition-transform duration-300"
            >
              <div className="text-3xl sm:text-4xl mb-4 text-primary">{service.icon}</div>
              <h1 className="text-sm sm:text-lg font-semibold text-center">
                {service.title}
              </h1>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;