import React from "react";
import { motion } from "framer-motion";
import { FaAward, FaChalkboardTeacher, FaUsers, FaClipboardCheck, FaProjectDiagram, FaCalendarAlt, FaClock, FaCertificate, FaHandsHelping } from "react-icons/fa";

const Key = () => {
  const highlights = [
    { text: "Personalized Career Counselling", icon: <FaHandsHelping /> },
    { text: "Certification Upon Completion", icon: <FaCertificate /> },
    { text: "24*7 Support", icon: <FaUsers /> },
    { text: "Interactive Quizzes & Hackathons", icon: <FaClipboardCheck /> },
    { text: "Weekly Feedback & Project Reviews", icon: <FaChalkboardTeacher /> },
    { text: "Weekend Classes", icon: <FaCalendarAlt /> },
    { text: "5-6 Hands-on Projects", icon: <FaProjectDiagram /> },
    { text: "100hrs+ of live training", icon: <FaClock /> },
    { text: "Global Community Building", icon: <FaAward /> },
  ];

  return (
    <section className="bg-gradient-to-r from-blue-50 to-blue-100 py-14">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h3 className="text-4xl font-bold text-blue-900 mb-4">Key Highlights</h3>
          <p className="text-gray-600">
            Our courses provide a well-rounded experience with a variety of unique benefits to support your learning journey.
          </p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {highlights.map((highlight, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="text-blue-500 text-4xl mb-4">
                {highlight.icon}
              </div>
              <h4 className="text-xl font-semibold text-center text-blue-900">{highlight.text}</h4>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Key;
