import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {  BaseUrl, connect, connectnow, getAllHiring } from '../../utils/constant';
import header from './images/Hiring_Header.webp';
import header2 from './images/Hiring_Header2.webp';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Circles } from 'react-loader-spinner';


function Student() {

    const navigate = useNavigate();
   
    const { name } = useParams();
    const [filteredData, setFilteredData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);



  // State variables for each category
  const [fullStackStudents, setFullStackStudents] = useState([]);
  const [dataScienceStudents, setDataScienceStudents] = useState([]);
  const [sasStudents, setSasStudents] = useState([]);
  const [dataengineeringStudents, setDataengineeringStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [urlStudentName, setUrlStudentName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shareURL, setShareURL] = useState(window.location.href);
  const [copySuccess, setCopySuccess] = useState(false);

  const [formValues, setFormValues] = useState({
      companyName: '',
      fullName: '',
      mobileNo: '',
      email: '',
      position: '',
      location: ''
  });
  const [errors, setErrors] = useState({
      companyName: false,
      fullName: false,
      mobileNo: false,
      email: false,
      position: false,
      location: false
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({
        ...formValues,
        [id]: value
    });
};

const validateForm = () => {
    const { companyName, position, fullName, location, mobileNo, email } = formValues;
    const newErrors = {};

    // Validate each field
    if (!companyName.trim()) newErrors.companyName = true;
    if (!fullName.trim()) newErrors.fullName = true;
    if (!mobileNo.trim()) newErrors.mobileNo = true;
    if (!email.trim()) newErrors.email = true;
    if (!position.trim()) newErrors.position = true;
    if (!location.trim()) newErrors.location = true;
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
};

const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = validateForm();

    if (formValid) {
        setIsLoading(true);
        try {
            const storeDataResponse = await fetch(connectnow, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formValues,
                    studentName: selectedStudent.name
                })
            });

            if (storeDataResponse.ok) {
                const sendEmailResponse = await fetch(connect, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...formValues,
                        studentName: selectedStudent.name
                    })
                });

                if (sendEmailResponse.ok) {
                    document.getElementById('exampleModal').style.display = 'none';
                    navigate("/hiring");
                } else {
                    console.error('Failed to send email');
                }
            } else {
                console.error('Failed to store data');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    }
};


useEffect(() => {
    // Function to fetch and filter data
    const fetchData = async () => {
        try {
            const response = await axios.get(getAllHiring);
            const data = response.data.data; // Adjust this based on your API structure

            // Remove underscores from the name
            const formattedName = name.replace(/_/g, ' ');

            // Filter data based on the formatted name
            const filtered = data.find(item => item.name === formattedName);
            setFilteredData(filtered);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, [name]);

useEffect(() => {
    // Show the modal when the component mounts
    setShowModal(true);
}, []);

const handleHireNowClick = () => {
    setShowModal(false);
    setShowFormModal(true);
};

    // Ensure filteredData is available before rendering
    if (!filteredData) return <p>Loading...</p>;



  return (
  <>
      <div className='container-fluid'>
                <div className="row top_header">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col p-5 header">
                                <h1 className="h1 text-slate-100">Hiring at Sankhyana - Work With Us</h1>
                                <p className="fs-5 text-slate-100 header_paragraph">We are committed to creating a positive work environment where everyone grows both personally and professionally. Join us to explore great opportunities.</p>
                                <Link to="/contact"><button className="p-2 mt-2 rounded-pill bg-light">Contact Us</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 pt-4 header_img">
                        <LazyLoad offsetVertical={300} debounce={false} once>
                            <img src={header} alt="high paying it jobs in Bangalore" height="163px" width="275px" loading='lazy' />
                        </LazyLoad>
                    </div>
                </div>

                <div className='row p-5'>
                    <div className='col-md-8 hiring_content'>
                        <ul className="list-disc">
                            <li>At Sankhyana, we provide various opportunities for individuals at every stage of their career journey. Whether you're seeking a full-time position or an internship experience, we offer meaningful roles that empower you to grow and make an impact.</li>
                            <li>Our Internship Program is designed to provide participants with practical knowledge and skills in various areas such as software development, project management, data analysis, marketing, customer service, and more.</li>
                            <li>We offer both short-term (3 months) and long-term (6 months) internships depending on your availability and interests.</li>
                        </ul>
                    </div>
                    <div className='col-md-4 header2'>
                        <LazyLoad offsetVertical={300} debounce={false} once>
                            <img src={header2} alt="high paid it companies in india" height="175px" width="364px" loading='lazy' />
                        </LazyLoad>
                    </div>
                </div>


                <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                    <Modal.Header>
                        <Modal.Title>Batch Topper <span className='text-orange-400'>{filteredData.name}</span> {filteredData.course}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-3'>
                                <img src={BaseUrl + `${filteredData.image}`} alt="Profile" width="100px" className="profile-img mt-3 ms-5" />
                            </div>
                            <div className='col-md-9'>
                                <p className='fs-5'><span className='fs-5 fw-bold'>Student Name: </span> {filteredData.course}</p>
                                <p className='fs-5'><span className='fs-5 fw-bold'>Location: </span> {filteredData.location}</p>
                                <p className='fs-5'><span className='fs-5 fw-bold'>Highest Qualification: </span> {filteredData.highest}</p>
                                <p className='fs-5'><span className='fs-5 fw-bold'>Skills Set: </span> {filteredData.skill}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleHireNowClick}>
                            Hire Now
                        </Button>
                     </Modal.Footer>
                </Modal>





                <Modal show={showFormModal} onHide={() => setShowFormModal(false)} size="lg" centered>
                    <Modal.Header>
                        <Modal.Title>Connect With <span className='text-orange-400'>{filteredData.name}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <form onSubmit={handleSubmit}>
          <div className="modal-body">
         


<div className="row">
<div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="companyName">Company Name</label>
                  <input type="text" className="form-control" id="companyName" value={formValues.companyName} onChange={handleInputChange} />
                  {errors.companyName && <p className="text-danger">Company name is required</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="fullName">Full Name</label>
                  <input type="text" className="form-control" id="fullName" value={formValues.fullName} onChange={handleInputChange} />
                  {errors.fullName && <p className="text-danger">Full name is required</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="mobileNo">Mobile No</label>
                  <input type="text" className="form-control" id="mobileNo" value={formValues.mobileNo} onChange={handleInputChange} />
                  {errors.mobileNo && <p className="text-danger">Mobile number is required</p>}
                </div>
</div>

         <div className="col-md-6">

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" value={formValues.email} onChange={handleInputChange} />
                  {errors.email && <p className="text-danger">Email is required</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="position">Position</label>
                  <input type="text" className="form-control" id="position" value={formValues.position} onChange={handleInputChange} />
                  {errors.position && <p className="text-danger">Position is required</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="location">Location</label>
                  <input type="text" className="form-control" id="location" value={formValues.location} onChange={handleInputChange} />
                  {errors.location && <p className="text-danger">Location is required</p>}
                </div>
             

                </div>
                </div>



          </div>
          <div className="modal-footer">

          {isLoading && <span className='text-success'>Data is submitted</span>}


               <button type="submit" className="btn btn-primary" disabled={isLoading}>
                  {isLoading ? <Circles height="20" width="20" color="#ffffff" /> : 'Submit'}
                </button>

          </div>
                        </form>
                   
                    </Modal.Body>
                 
                </Modal>
            </div>
  </>
  )
}

export default Student
