// import React from "react";
// import { IoMdMenu } from "react-icons/io";
// import { motion } from "framer-motion";
// import slogo from "../../assets/logo.cef6df0f62c8279a1dc4.webp";

// const NavbarMenu = [
//   {
//     id: 1,
//     title: "",
//     path: "/",
//   },

// ];
// const Navbar = () => {
//   return (
//     <nav className="relative z-20 rounded-lg">
//       <motion.div
//         initial={{ opacity: 0, y: -50 }}
//         animate={{ opacity: 1, y: 0 }}
//         className="container py-10 flex justify-between items-center"
//       >
//         {/* Logo section */}
//         <div className="flex gap-4">
//         <div>
//             <img src={slogo} alt="" />
//         </div>
//         <div>
//           <h1 className="font-bold text-4xl">Sankhyana Consultancy Services Private Limited</h1>
//         </div>
//         </div>
//         {/* Menu section */}
//         <div className="hidden lg:block">
//           <ul className="flex items-center gap-3">
//             {NavbarMenu.map((menu) => (
//               <li key={menu.id}>
//                 <a
//                   href={menu.path}
//                   className="inline-block py-2 px-3 hover:text-secondary relative group"
//                 >
//                   <div className="w-2 h-2 bg-secondary absolute mt-4 rounded-full left-1/2 -translate-x-1/2 top-1/2 bottom-0 group-hover:block hidden"></div>
//                   {menu.title}
//                 </a>
//               </li>
//             ))}
//             {/* <button className="primary-btn">Sign In</button> */}
//           </ul>
//         </div>
      
//       </motion.div>
//     </nav>
//   );
// };

// export default Navbar;















import React from "react";
// import { IoMdMenu } from "react-icons/io";
import { motion } from "framer-motion";
import slogo from "../../assets/logo.cef6df0f62c8279a1dc4.webp";

const NavbarMenu = [
  {
    id: 1,
    title: "",
    path: "/",
  },
];

const Navbar = () => {
  return (
    <nav className="relative z-20 rounded-lg">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        className="container py-4 sm:py-10 flex justify-between items-center"
      >
        {/* Logo section */}
        <div className="flex gap-2 sm:gap-4 items-center">
          <div className="w-10 sm:w-auto">
            <img src={slogo} alt="Logo" className="h-auto w-full" />
          </div>
          <div>
            <h1 className="font-bold text-lg sm:text-2xl lg:text-4xl">
              Sankhyana Consultancy Services Private Limited
            </h1>
          </div>
        </div>

        {/* Mobile menu icon */}
        {/* <div className="lg:hidden"> */}
          {/* <button className="text-2xl"> */}
            {/* <IoMdMenu /> */}
          {/* </button> */}
        {/* </div> */}

        {/* Menu section for large screens */}
        <div className="hidden lg:block">
          <ul className="flex items-center gap-3">
            {NavbarMenu.map((menu) => (
              <li key={menu.id}>
                <a
                  href={menu.path}
                  className="inline-block py-2 px-3 hover:text-secondary relative group"
                >
                  <div className="w-2 h-2 bg-secondary absolute mt-4 rounded-full left-1/2 -translate-x-1/2 top-1/2 bottom-0 group-hover:block hidden"></div>
                  {menu.title}
                </a>
              </li>
            ))}
            {/* <button className="primary-btn">Sign In</button> */}
          </ul>
        </div>
      </motion.div>
    </nav>
  );
};

export default Navbar;