import React from 'react'
import './Error.css'
function Error() {
  return (
  <>
   <div class="error_container">
      <div class="gif">
        <img src="https://i.postimg.cc/2yrFyxKv/giphy.gif" alt="gif_ing" />
      </div>
      <div class="error_content">
        <h1 class="error_main-heading">This page is gone.</h1>
        <p className='error_p'>
          ...maybe the page you're looking for is not found or never existed.
        </p>
        <a href="http://localhost:3000/" target="blank">
          <button className='error_btn'>Back to home <i class="far fa-hand-point-right"></i></button>
        </a>
      </div>
    </div>
  </>
  )
}

export default Error