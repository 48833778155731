// import React from "react";
// import { motion } from "framer-motion";
// import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// const Banner2 = () => {
//   const fadeIn = {
//     initial: { opacity: 0, y: 20 },
//     animate: { opacity: 1, y: 0 },
//   };

//   const FadeUpText = (delay) => {
//     return {
//       initial: { opacity: 0, y: 30 },
//       animate: {
//         opacity: 1,
//         y: 0,
//         transition: {
//           duration: 0.5,
//           delay: delay,
//           ease: "easeInOut",
//         },
//       },
//     };
//   };

//   const videos = [
//     { title: "Data Science Video 1", src: "https://www.youtube.com/embed/-QnNI_NoUNQ" },
//     { title: "Data Science Video 2", src: "https://www.youtube.com/embed/J6KAPGmGskM" },
//     { title: "Data Science Video 3", src: "https://www.youtube.com/embed/nEGalguhwpg" },
//     { title: "Data Science Video 4", src: "https://www.youtube.com/embed/h7sBEg9SPOE" },
//     { title: "Data Science Video 5", src: "https://www.youtube.com/embed/kkLGFsNzlAs" },
//     { title: "Data Science Video 6", src: "https://www.youtube.com/embed/SFcmtkCDtM0" },
//     { title: "Data Science Video 7", src: "https://www.youtube.com/embed/e59ds-7Ys2E" },
//     { title: "Data Science Video 8", src: "https://www.youtube.com/embed/L4YwmEdLak0" },
//     { title: "Data Science Video 9", src: "https://www.youtube.com/embed/wt7tjG2O8Yw" },
//     { title: "Data Science Video 10", src: "https://www.youtube.com/embed/8VapCSVxlTU" },
//     { title: "Data Science Video 11", src: "https://www.youtube.com/embed/hxCQbtYlv4I" },
//     { title: "Data Engineering Video 1", src: "https://www.youtube.com/embed/7B8tBFlUHRU" },
//     { title: "Data Engineering Video 2", src: "https://www.youtube.com/embed/V6FOjzUhb-M" },
//     { title: "Data Engineering Video 3", src: "https://www.youtube.com/embed/sYLxathrj74" },
//     { title: "Data Engineering Video 4", src: "https://www.youtube.com/embed/8NQrOMuUnLk" },
//     { title: "Full Stack Development Video 1", src: "https://www.youtube.com/embed/qoFjqmr4iTM" },
//     { title: "Full Stack Development Video 2", src: "https://www.youtube.com/embed/RqNF4ysk1Sw" },
//     { title: "Full Stack Development Video 3", src: "https://www.youtube.com/embed/DhMdnDwGClc" },
//     { title: "Full Stack Development Video 4", src: "https://www.youtube.com/embed/S8DA-TgFLIc" },
//     { title: "Full Stack Development Video 5", src: "https://www.youtube.com/embed/JieyU6BoXEY" },
//   ];

//   return (
//     <section className="bg-blue-100 py-14 md:py-24">
//       <div className="container flex flex-col items-center space-y-6">
//         {/* Feedback Videos Section */}
//         <motion.div
//           initial={{ opacity: 0, y: -20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="text-center space-y-4"
//         >
//           <motion.h1
//             variants={FadeUpText(0.1)}
//             initial="initial"
//             whileInView="animate"
//             className="text-4xl font-bold !leading-snug"
//           >
//             Check Out Our Students' Feedback Videos
//           </motion.h1>
//           <motion.p
//             variants={FadeUpText(0.2)}
//             initial="initial"
//             whileInView="animate"
//             className="text-dark2"
//           >
//             Hear from our students about their experiences in various courses:
//           </motion.p>
//         </motion.div>

//         {/* Swiper Slider */}
//         <div className="w-full mt-6 relative">
//           <Swiper
//             slidesPerView={3}
//             spaceBetween={30}
//             loop={true}
//             autoplay={{
//               delay: 3000,
//               disableOnInteraction: false,
//             }}
//             modules={[Autoplay, Pagination, Navigation]}
//             className="mySwiper"
//             pagination={{
//               clickable: true,
//               el: '.swiper-pagination',
//             }}
//             navigation={{
//               nextEl: '.swiper-button-next',
//               prevEl: '.swiper-button-prev',
//             }}
//           >
//             {videos.map((video, index) => (
//               <SwiperSlide key={index}>
//                 <motion.div
//                   className="w-full pl-10 pr-10"
//                   // variants={fadeIn}
//                   initial="initial"
//                   whileInView="animate"
//                   transition={{ delay: 0.5 + index * 0.1, duration: 0.5 }}
//                 >
//                   <motion.iframe
//                     // variants={fadeIn}
//                     initial="initial"
//                     whileInView="animate"
//                     transition={{ delay: 0.2 + index * 0.1, duration: 0.2 }}
//                     width="100%"
//                     height="250"
//                     src={video.src}
//                     frameBorder="0"
//                     allowFullScreen
//                     className="rounded-lg shadow-lg"
//                     loading="lazy"
//                   />
//                 </motion.div>
//               </SwiperSlide>
//             ))}
//           </Swiper>
//           {/* Navigation and Pagination */}
//           <div className="absolute top-40 left-0 right-0 flex justify-between">
//             <div className="swiper-button-prev text-black p-1 hover:text-white" />
//             <div className="swiper-button-next text-black p-1 hover:text-white" />
//           </div>
//           <div className="flex justify-center mt-10 gap-5">
//             <div className="swiper-pagination flex justify-center" />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Banner2;


















import React from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Banner2 = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  };

  const FadeUpText = (delay) => {
    return {
      initial: { opacity: 0, y: 30 },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: delay,
          ease: "easeInOut",
        },
      },
    };
  };

  const videos = [
    { title: "Data Science Video 1", src: "https://www.youtube.com/embed/-QnNI_NoUNQ" },
    { title: "Data Science Video 2", src: "https://www.youtube.com/embed/J6KAPGmGskM" },
    { title: "Data Science Video 3", src: "https://www.youtube.com/embed/nEGalguhwpg" },
    { title: "Data Science Video 4", src: "https://www.youtube.com/embed/h7sBEg9SPOE" },
    { title: "Data Science Video 5", src: "https://www.youtube.com/embed/kkLGFsNzlAs" },
    { title: "Data Science Video 6", src: "https://www.youtube.com/embed/SFcmtkCDtM0" },
    { title: "Data Science Video 7", src: "https://www.youtube.com/embed/e59ds-7Ys2E" },
    { title: "Data Science Video 8", src: "https://www.youtube.com/embed/L4YwmEdLak0" },
    { title: "Data Science Video 9", src: "https://www.youtube.com/embed/wt7tjG2O8Yw" },
    { title: "Data Science Video 10", src: "https://www.youtube.com/embed/8VapCSVxlTU" },
    { title: "Data Science Video 11", src: "https://www.youtube.com/embed/hxCQbtYlv4I" },
    { title: "Data Engineering Video 1", src: "https://www.youtube.com/embed/7B8tBFlUHRU" },
    { title: "Data Engineering Video 2", src: "https://www.youtube.com/embed/V6FOjzUhb-M" },
    { title: "Data Engineering Video 3", src: "https://www.youtube.com/embed/sYLxathrj74" },
    { title: "Data Engineering Video 4", src: "https://www.youtube.com/embed/8NQrOMuUnLk" },
    { title: "Full Stack Development Video 1", src: "https://www.youtube.com/embed/qoFjqmr4iTM" },
    { title: "Full Stack Development Video 2", src: "https://www.youtube.com/embed/RqNF4ysk1Sw" },
    { title: "Full Stack Development Video 3", src: "https://www.youtube.com/embed/DhMdnDwGClc" },
    { title: "Full Stack Development Video 4", src: "https://www.youtube.com/embed/S8DA-TgFLIc" },
    { title: "Full Stack Development Video 5", src: "https://www.youtube.com/embed/JieyU6BoXEY" },
  ];

  return (
    <section className="bg-blue-100 py-14 md:py-24">
      <div className="container flex flex-col items-center space-y-6">
        {/* Feedback Videos Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center space-y-4"
        >
          <motion.h1
            variants={FadeUpText(0.1)}
            initial="initial"
            whileInView="animate"
            className="text-4xl font-bold !leading-snug"
          >
            Check Out Our Students' Feedback Videos
          </motion.h1>
          <motion.p
            variants={FadeUpText(0.2)}
            initial="initial"
            whileInView="animate"
            className="text-dark2"
          >
            Hear from our students about their experiences in various courses:
          </motion.p>
        </motion.div>

        {/* Swiper Slider */}
        <div className="w-full mt-6 relative">
          <Swiper
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            pagination={{
              clickable: true,
              el: '.swiper-pagination',
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {videos.map((video, index) => (
              <SwiperSlide key={index}>
                <motion.div
                  className="w-full pl-10 pr-10"
                  initial="initial"
                  whileInView="animate"
                  transition={{ delay: 0.5 + index * 0.1, duration: 0.5 }}
                >
                  <motion.iframe
                    initial="initial"
                    whileInView="animate"
                    transition={{ delay: 0.2 + index * 0.1, duration: 0.2 }}
                    width="100%"
                    height="250"
                    src={video.src}
                    frameBorder="0"
                    allowFullScreen
                    className="rounded-lg shadow-lg"
                    loading="lazy"
                  />
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Navigation and Pagination */}
          <div className="absolute top-40 left-0 right-0 flex justify-between">
            <div className="swiper-button-prev text-black p-1 hover:text-white" />
            <div className="swiper-button-next text-black p-1 hover:text-white" />
          </div>
          <div className="flex justify-center mt-10 gap-5">
            <div className="swiper-pagination flex justify-center" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner2;















