import React, { useEffect } from 'react'
import privacy from './Images/privacy-policy.webp'
import LazyLoad from 'react-lazy-load';
import {Link} from 'react-router-dom'

function Privacy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
   <>
<div className='container-fluid'>

<div className='row d-flex justify-content-center pt-3'>
          <div className='col-md-6'>
            <h1 className='h1 text-center'>Privacy Policy</h1>
          </div>
        </div>

    <div className='row pt-1 mb-5'>
        <div className='col-md-8 shadow-xl'>
        
            <div className='row p-2 text-justify'>
        <p>We're writing to let you know that we're updating our privacy policy in preparation for the EU's General Data Protection Regulations ("GDPR") which will go into effect on May 25th, 2018.</p>
        <p>While the GDPR is a European law, we are taking this opportunity to clarify and apply our privacy policy to everyone, no matter where you're located.</p>
        <div class="summary">
            <h2>Here's a quick summary of our changes:</h2>
            <ul className='list-disc pl-5'>
                <li>We're being more specific on how we use the information we collect, and how long we keep the data.</li>
                <li>We're being more specific about your data preferences and your data rights.</li>
                <li>These changes will not affect the way you use Patreon.</li>
                <li>You do not need to take any action.</li>
            </ul>
        </div>
        <p>If you continue using Patreon on or after May 25, 2018, you are agreeing to these terms.</p>
        <p class="contact">And as always, if you have questions about these changes, feel free to email us at <a href="mailto:privacy@patreon.com">privacy@patreon.com</a>.</p>
      
      
        <div className='row p-4 d-flex justify-content-center'>
        <div className='col-md-9 shadow-xl rounded-lg text-center'> 
        <p className="fw-bold">And as always, if you have questions about these changes, feel free to email us at <a href="mailto:privacy@patreon.com">privacy@patreon.com</a>.</p>
     <Link to="/contact"><button className='btn border-animation mt-2'>Contact Us</button></Link>
        </div>
      </div>
            </div>
        </div>

        <div className='col-md-4 d-flex justify-content-center align-items-center contact_img'>
            <LazyLoad debounce={false} once>
              <img src={privacy} alt='best software training institute in India' className='img-fluid' />
            </LazyLoad>
          </div>

    </div>
</div>
   </>
  )
}

export default Privacy