// import React from "react";
// import { motion } from "framer-motion";
// import { Link } from "react-router-dom";

// const Tools = () => {
//   // Motion variants for entrance animation
//   const fadeIn = {
//     initial: { opacity: 0, y: 20 },
//     animate: { opacity: 1, y: 0 },
//   };

//   const hoverEffect = {
//     scale: 1.05,
//     transition: {
//       duration: 0.2, // Faster hover animation
//       ease: "easeInOut",
//     },
//   };

//   const courses = [
//     {
//       title: "Data Science",
//       tools: [
// "Data mining with Python", 
// "Statistics with Python", 
// "Machine Learning", 
// "Artificial Intelligence", 
// "Programming Languages", 
// "Python", 
// "Data Manipulation and Analysis", 
// "Pandas", 
// "NumPy", 
// "Data Visualization", 
// "Matplotlib", 
// "Seaborn", 
// "Machine Learning Libraries", 
// "Scikit-learn", 
// "TensorFlow", 
// "Streamlit", 
// "Statistical Analysis Tools", 
// "SciPy", 
// "Databases", 
// "SQL (MySQL)", 
// "Artificial Intelligence", 
// "Natural Language Processing", 
// "Artificial Neural Network"

//       ],
//       link:"/training"
//     },
//     {
//       title: "Full Stack",
//       tools: [
//    "Frontend Technologies", 
// "HTML", 
// "CSS", 
// "JavaScript", 
// "Bootstrap", 
// "jQuery", 
// "SAS", 
// "React.js", 
// "Backend Technologies", 
// "Flask", 
// "Django", 
// "Python", 
// "Databases", 
// "SQL (MySQL)", 
// "SQLite", 
// "Version Control", 
// "GitHub", 
// "APIs", 
// "Jango Rest API framework", 
// "RESTful API"

//       ],
//       link:"/training"
//     },
//     {
//       title: "Data Engineering",
//       tools: [
//        "Shell scripting and GIT basics", 
// "Relational Database Management System", 
// "Python programming for data analytics", 
// "Big Data and Hadoop", 
// "Java for data engineering", 
// "Data Warehousing", 
// "Amazon Web Service", 
// "Data Pipeline Tools", 
// "Apache Airflow", 
// "ETL Tools", 
// "Apache Spark (for ETL)", 
// "Databases", 
// "SQL (MySQL)", 
// "NoSQL (MongoDB)", 
// "Big Data Technologies", 
// "Apache Hadoop", 
// "Apache Kafka", 
// "Cloud Services", 
// "AWS (Amazon Web Services)"

//       ],
//       link:"/training"
//     },
//   ];

//   return (
//     <section className="bg-blue-100 py-14 md:py-24">
//       <div className="container mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: -20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="text-center mb-12"
//         >
//           <motion.h2
//             initial="initial"
//             animate="animate"
//             variants={fadeIn}
//             className="text-4xl font-bold text-blue-900 mb-4"
//           >
//             Tools You'll Learn
//           </motion.h2>
//           <p className="text-gray-600 mb-8">
//             Gain practical skills with industry-standard tools in our courses.
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//           {courses.map((course, index) => (
//             <motion.div
//               key={index}
//               variants={fadeIn}
//               initial="initial"
//               animate="animate"
//               transition={{ duration: 0.5, delay: index * 0.2 }}
//               className="bg-white text-gray-800 shadow-lg rounded-lg border-2 border-gray-300 p-6 transition-transform duration-300 transform hover:shadow-xl hover:scale-105"
//               whileHover={hoverEffect}
//             >
//               <h3 className="text-3xl font-bold text-center mb-4">
//                 {course.title}
//               </h3>
//               <div >
//                 {course.tools.map((tool, i) => (
//                 <div key={i} className="text-lg mb-1"> 
//                   <ul>
//                     <li className="text-center"> {tool}</li>
//                  </ul>
                                                  
//                   </div>
                

//                 ))}
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Tools;

















import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Tools = () => {
  // Motion variants for entrance animation
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  };

  const hoverEffect = {
    scale: 1.05,
    transition: {
      duration: 0.2, // Faster hover animation
      ease: "easeInOut",
    },
  };

  const courses = [
    {
      title: "Data Science",
      tools: [
        { heading: "Programming Languages", items: ["Python"] },
        { heading: "Data Manipulation and Analysis", items: ["Pandas", "NumPy"] },
        { heading: "Data Visualization", items: ["Matplotlib", "Seaborn"] },
        { heading: "Machine Learning Libraries", items: ["Scikit-learn", "TensorFlow", "Streamlit"] },
        { heading: "Statistical Analysis Tools", items: ["SciPy"] },
        { heading: "Databases", items: ["SQL (MySQL)"] },
        { heading: "Artificial Intelligence", items: ["Natural Language Processing", "Artificial Neural Network"] },
      ],
      link: "/training"
    },
    {
      title: "Full Stack",
      tools: [
        { heading: "Frontend Technologies", items: ["HTML", "CSS", "JavaScript", "Bootstrap", "jQuery", "SAS", "React.js"] },
        { heading: "Backend Technologies", items: ["Flask", "Django", "Python"] },
        { heading: "Databases", items: ["SQL (MySQL)", "SQLite"] },
        { heading: "Version Control", items: ["GitHub"] },
        { heading: "APIs", items: ["Django Rest API framework", "RESTful API"] },
      ],
      link: "/training"
    },
    {
      title: "Data Engineering",
      tools: [
        { heading: "Shell Scripting and GIT Basics", items: [] },
        { heading: "Relational Database Management System", items: [] },
        { heading: "Python Programming for Data Analytics", items: [] },
        { heading: "Big Data and Hadoop", items: [] },
        { heading: "Java for Data Engineering", items: [] },
        { heading: "Data Warehousing", items: ["Amazon Web Services"] },
        { heading: "Data Pipeline Tools", items: ["Apache Airflow"] },
        { heading: "ETL Tools", items: ["Apache Spark (for ETL)"] },
        { heading: "Databases", items: ["SQL (MySQL)", "NoSQL (MongoDB)"] },
        { heading: "Big Data Technologies", items: ["Apache Hadoop", "Apache Kafka"] },
        { heading: "Cloud Services", items: ["AWS (Amazon Web Services)"] },
      ],
      link: "/training"
    },
  ];

  return (
    <section className="bg-blue-100 py-14 md:py-24">
      <div className="container mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <motion.h2
            initial="initial"
            animate="animate"
            variants={fadeIn}
            className="text-4xl font-bold text-blue-900 mb-4"
          >
            Tools You'll Learn
          </motion.h2>
          <p className="text-gray-600 mb-8">
            Gain practical skills with industry-standard tools in our courses.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {courses.map((course, index) => (
            <motion.div
              key={index}
              variants={fadeIn}
              initial="initial"
              animate="animate"
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="bg-white text-gray-800 shadow-lg rounded-lg border-2 border-gray-300 p-6 transition-transform duration-300 transform hover:shadow-xl hover:scale-105"
              whileHover={hoverEffect}
            >
              <h3 className="text-3xl font-bold text-center mb-2">
                {course.title}
              </h3>
              <div className="">
                {course.tools.map((toolGroup, i) => (
                  <div key={i} className="mb-2">
                    <h4 className="font-semibold text-lg">{toolGroup.heading}</h4>
                    <ul className="list-disc list-inside">
                      {toolGroup.items.map((tool, j) => (
                        <li key={j} className="text-lg">{tool}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Tools;
