// import React from "react";
// import { motion } from "framer-motion";

// const WhyChooseUs = () => {
//   const fadeIn = {
//     initial: { opacity: 0, y: 20 },
//     animate: { opacity: 1, y: 0 },
//   };

//   const FadeUpText = (delay) => {
//     return {
//       initial: { opacity: 0, y: 30 },
//       animate: {
//         opacity: 1,
//         y: 0,
//         transition: {
//           duration: 0.5,
//           delay: delay,
//           ease: "easeInOut",
//         },
//       },
//     };
//   };

//   const content = [
//     {
//       title: "Budget Friendly Fees",
//       description:
//         "Our scholarships ensure that top quality education is within everyone's reach.",
//     },
//     {
//       title: "Practical, Hands-On Training",
//       description:
//         "We emphasize learning through doing, giving you valuable real-world experience.",
//     },
//     {
//       title: "CV Optimization and Profile Enhancement",
//       description:
//         "Get expert help to make your CV shine and stand out to potential employers.",
//     },
//     {
//       title: "Interview Coaching",
//       description:
//         "We offer mock interviews to build your confidence and help you ace any interview.",
//     },
//     {
//       title: "Personalized Career Counselling",
//       description:
//         "One-on-one sessions tailored to guide you towards career success.",
//     },
//     {
//       title: "Certification Upon Completion",
//       description:
//         "Earn an accredited certificate to enhance your professional qualifications.",
//     },
//     {
//       title: "Doubt Clearing Sessions",
//       description: "Get personalized assistance from expert mentors.",
//     },
//     {
//       title: "Interactive Quizzes & Hackathons",
//       description:
//         "Test your knowledge in real-world scenarios and compete with peers to stay ahead.",
//     },
//     {
//       title: "Weekly Feedback & Project Reviews",
//       description:
//         "Improve constantly with constructive feedback from industry professionals.",
//     },
//     {
//       title: "Comprehensive Learning Support",
//       description:
//         "Our courses are designed with a hands-on approach, ensuring you gain practical skills that employers value.",
//     },
//     {
//       title: "Comprehensive Live Sessions",
//       description:
//         "Engage in interactive, live sessions where you can ask questions in real-time, work on projects, and collaborate with peers.",
//     },
//   ];

//   const firstColumnContent = content.slice(0, Math.ceil(content.length / 2));
//   const secondColumnContent = content.slice(Math.ceil(content.length / 2));

//   return (
//     <section className="bg-slate-100 py-14 md:py-24">
//       <div className="container mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: -20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="text-center mb-12"
//         >
//           <motion.h2
//             variants={FadeUpText(0.1)}
//             initial="initial"
//             animate="animate"
//             className="text-4xl font-bold text-blue-900"
//           >
//             Why Choose Us
//           </motion.h2>
//         </motion.div>

//         {/* Two Column Layout with Line in Center */}
//         <div className="flex justify-between items-start">
//           {/* First Column */}
//           <div className="w-full md:w-1/2 space-y-6 text-lg text-gray-700">
//             {firstColumnContent.map((item, index) => (
//               <motion.div
//                 key={index}
//                 variants={FadeUpText(index * 0.2)}
//                 initial="initial"
//                 animate="animate"
//                 className="flex flex-col items-start"
//               >
//                 <h3 className="text-xl font-semibold text-blue-700">
//                   {item.title}
//                 </h3>
//                 <p className="mt-1">{item.description}</p>
//               </motion.div>
//             ))}
//           </div>

//           {/* Center Vertical Line */}
//           <div className="hidden md:block mx-4">
//             <div
//               className="border-l-4 border-gray-500"
//               style={{ height: '100%', minHeight: '680px' }}
//             ></div>
//           </div>

//           {/* Second Column */}
//           <div className="w-full md:w-1/2 space-y-6 text-lg text-gray-700">
//             {secondColumnContent.map((item, index) => (
//               <motion.div
//                 key={index}
//                 variants={FadeUpText((index + firstColumnContent.length) * 0.2)}
//                 initial="initial"
//                 animate="animate"
//                 className="flex flex-col items-start"
//               >
//                 <h3 className="text-xl font-semibold text-blue-700">
//                   {item.title}
//                 </h3>
//                 <p className="mt-1">{item.description}</p>
//               </motion.div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default WhyChooseUs;


















import React from "react";
import { motion } from "framer-motion";

const WhyChooseUs = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  };

  const FadeUpText = (delay) => {
    return {
      initial: { opacity: 0, y: 30 },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: delay,
          ease: "easeInOut",
        },
      },
    };
  };

  const content = [
    {
      title: "Budget Friendly Fees",
      description:
        "Our scholarships ensure that top quality education is within everyone's reach.",
    },
    {
      title: "Practical, Hands-On Training",
      description:
        "We emphasize learning through doing, giving you valuable real-world experience.",
    },
    {
      title: "CV Optimization and Profile Enhancement",
      description:
        "Get expert help to make your CV shine and stand out to potential employers.",
    },
    {
      title: "Interview Coaching",
      description:
        "We offer mock interviews to build your confidence and help you ace any interview.",
    },
    {
      title: "Personalized Career Counselling",
      description:
        "One-on-one sessions tailored to guide you towards career success.",
    },
    {
      title: "Certification Upon Completion",
      description:
        "Earn an accredited certificate to enhance your professional qualifications.",
    },
    {
      title: "Doubt Clearing Sessions",
      description: "Get personalized assistance from expert mentors.",
    },
    {
      title: "Interactive Quizzes & Hackathons",
      description:
        "Test your knowledge in real-world scenarios and compete with peers to stay ahead.",
    },
    {
      title: "Weekly Feedback & Project Reviews",
      description:
        "Improve constantly with constructive feedback from industry professionals.",
    },
    {
      title: "Comprehensive Learning Support",
      description:
        "Our courses are designed with a hands-on approach, ensuring you gain practical skills that employers value.",
    },
    {
      title: "Comprehensive Live Sessions",
      description:
        "Engage in interactive, live sessions where you can ask questions in real-time, work on projects, and collaborate with peers.",
    },
  ];

  return (
    <section className="bg-slate-100 py-14 md:py-24">
      <div className="container mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <motion.h2
            variants={FadeUpText(0.1)}
            initial="initial"
            animate="animate"
            className="text-3xl md:text-4xl font-bold text-blue-900"
          >
            Why Choose Us
          </motion.h2>
        </motion.div>

        {/* Responsive Single Column on Mobile, Two Columns on Larger Screens */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-lg md:text-xl text-gray-700">
          {content.map((item, index) => (
            <motion.div
              key={index}
              variants={FadeUpText(index * 0.2)}
              initial="initial"
              animate="animate"
              className="flex flex-col items-start"
            >
              <h3 className="text-xl md:text-2xl font-semibold text-blue-700">
                {item.title}
              </h3>
              <p className="mt-1 text-sm md:text-base">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;